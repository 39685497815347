<template>
  <div class="full-height">
    <!--nav-->

    <div class="sect bg-secb full-height">
      <div class="container-fluid">
        <div class="row justify-content-center">
          
          <div class="col-lg-4 cardb px-4 pb-4 bg-bg card-loginb  mt-5">
            <div class=" pb-0 ">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
 name:"auth"
}
</script>

<style>

</style>